import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1024 1024">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="">


<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M750.503662,579.494812 
	C742.073181,591.473511 729.483582,594.783813 716.522461,596.613953 
	C700.563660,598.867432 685.147522,596.650574 671.822205,586.840759 
	C654.444275,574.047546 645.807922,556.961670 646.603638,534.965027 
	C647.516235,509.737793 659.775024,491.916351 682.360535,482.588379 
	C716.217468,468.605225 757.300110,483.321625 765.695312,523.339905 
	C769.995178,543.836426 766.568054,563.507996 750.503662,579.494812 
M684.297424,550.092163 
	C688.491150,560.540771 702.684021,566.278259 712.017822,564.816162 
	C722.770752,563.131653 728.649780,555.860474 731.897278,546.049133 
	C733.288086,541.847107 733.347473,537.389832 732.590881,533.186340 
	C729.886230,518.159729 717.169678,508.042725 702.062439,510.746277 
	C685.586975,513.694763 676.524170,534.696106 684.297424,550.092163 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M294.481689,528.348022 
	C294.126801,537.218323 294.045135,537.300110 285.500336,537.332458 
	C276.511810,537.366455 267.523285,537.392090 258.534760,537.421814 
	C252.270477,537.442505 252.251114,537.454163 252.246948,543.953674 
	C252.237625,558.440613 252.251984,572.927612 252.226929,587.414551 
	C252.216705,593.327209 251.514572,593.710693 245.475266,593.560547 
	C237.009933,593.350037 228.527328,593.291504 220.067963,593.601257 
	C215.487671,593.768921 213.780304,592.163269 213.788040,587.617676 
	C213.865402,542.158630 213.850510,496.699402 213.802917,451.240326 
	C213.798370,446.894531 215.495575,444.850220 220.029327,444.866760 
	C243.507874,444.952362 266.987091,444.948944 290.465576,444.855743 
	C294.768860,444.838654 296.375977,446.581940 296.259949,450.854370 
	C296.047577,458.674835 296.057617,466.508789 296.257935,474.329926 
	C296.364197,478.479858 294.591339,480.004028 290.631500,479.968384 
	C279.975494,479.872528 269.315063,480.068207 258.662872,479.841644 
	C254.055664,479.743591 251.808945,481.160980 252.145355,486.076141 
	C252.406189,489.887146 252.203537,493.732086 252.156097,497.561310 
	C252.115692,500.821350 253.240723,502.349487 256.922089,502.122650 
	C267.727844,501.456757 278.531372,501.898499 289.338776,502.605774 
	C293.170105,502.856476 294.876831,504.419922 294.846588,508.013275 
	C294.790649,514.661255 294.600494,521.308105 294.481689,528.348022 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M374.051514,478.621277 
	C400.082001,480.040253 423.354584,502.600800 426.042114,528.764954 
	C427.938660,547.228149 424.602020,564.170776 411.806000,578.520081 
	C403.496216,587.838623 393.077911,593.431213 381.166779,596.113953 
	C361.440186,600.556885 342.993927,597.339417 327.048492,584.487244 
	C310.569458,571.205017 304.305054,553.316895 306.164917,532.598145 
	C307.875427,513.543396 316.044769,497.937744 332.400635,487.218903 
	C334.472839,485.860901 336.699524,483.962982 339.239532,485.357727 
	C341.636536,486.674011 340.961456,489.484314 340.988190,491.747314 
	C341.079254,499.462616 342.748779,506.952576 344.416290,514.401978 
	C345.386749,518.737244 344.808533,522.451477 342.995209,526.423218 
	C338.397949,536.492859 341.425873,550.972351 349.303589,558.223694 
	C356.918518,565.233215 365.757874,566.934692 375.241577,563.259766 
	C385.157562,559.417358 390.233826,551.490051 391.498077,541.113953 
	C393.418549,525.351746 384.185394,512.860840 368.504456,510.314484 
	C364.164734,509.609772 362.979797,507.535889 363.344269,503.542206 
	C364.034241,495.981842 366.582062,488.947815 369.207336,481.937836 
	C369.918976,480.037567 371.217987,478.424896 374.051514,478.621277 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M558.779541,563.900269 
	C571.960266,546.126770 584.919312,528.645569 597.884216,511.156403 
	C595.451233,508.264404 592.744873,508.257935 590.141479,508.232910 
	C580.645996,508.141754 571.148132,508.035309 561.654175,508.160950 
	C557.287292,508.218750 555.135620,506.840698 555.523499,502.075958 
	C555.860229,497.939270 555.663818,493.749725 555.548218,489.587830 
	C555.363892,482.950378 556.318665,481.773163 563.183472,481.721222 
	C578.009888,481.609009 592.837524,481.632385 607.664612,481.627197 
	C615.994507,481.624268 624.328918,481.849152 632.652527,481.632690 
	C637.276001,481.512482 639.027344,483.604248 638.894775,487.875671 
	C638.703796,494.032288 638.901123,500.240051 638.147888,506.328857 
	C637.425903,512.164612 632.839172,516.190857 629.613708,520.773987 
	C620.037048,534.381653 610.274292,547.858337 600.631165,561.419556 
	C599.712341,562.711670 598.411194,563.979614 599.027100,566.188965 
	C602.267639,567.414124 605.776733,566.792358 609.187073,566.840942 
	C617.015564,566.952576 624.847473,566.935608 632.676514,566.845154 
	C636.546875,566.800476 638.462952,568.388245 638.699890,572.380981 
	C639.055237,578.370178 637.990479,584.321594 638.615601,590.337585 
	C638.925110,593.316101 636.092468,593.318176 633.885620,593.338806 
	C624.568298,593.426147 615.250732,593.486877 605.933350,593.570435 
	C592.281067,593.692810 578.629028,593.904358 564.976624,593.928650 
	C555.321533,593.945801 555.287476,593.836731 554.953674,584.341309 
	C554.708862,577.377075 553.722473,570.257568 558.779541,563.900269 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M480.126709,547.975586 
	C480.773529,561.274780 481.208435,574.081604 480.948425,586.906189 
	C480.821014,593.190369 479.947388,593.819519 473.532410,593.591309 
	C466.059265,593.325378 458.544128,593.106812 451.097961,593.613647 
	C446.152313,593.950317 444.884369,591.867981 444.888428,587.640808 
	C444.896118,579.648010 444.751007,571.654785 444.779114,563.662231 
	C444.835907,547.509460 445.003632,531.357178 445.047394,515.204407 
	C445.064819,508.779694 444.433807,508.263397 437.740540,508.158875 
	C430.373322,508.043762 429.924622,507.750000 429.262848,500.316895 
	C428.939301,496.682892 428.929565,492.987610 429.163086,489.344757 
	C429.565216,483.071808 431.348053,481.501587 437.492737,481.358765 
	C444.837616,481.188049 445.734680,480.331238 445.613678,473.044769 
	C445.492126,465.723755 445.265350,458.403992 445.027710,451.085541 
	C444.880249,446.544830 446.620239,444.449371 451.531830,444.668793 
	C459.010895,445.002930 466.517700,444.839447 474.009766,444.711548 
	C478.405487,444.636536 480.402191,446.407776 480.292023,450.944397 
	C480.089996,459.266785 480.271820,467.598236 480.087280,475.921356 
	C479.991577,480.239532 481.843353,482.068787 486.078156,481.729584 
	C487.402802,481.623474 488.741821,481.710907 490.073151,481.672150 
	C493.952759,481.559235 496.006012,483.325104 496.018311,487.310211 
	C496.033722,492.305908 496.098419,497.301453 496.137726,502.297089 
	C496.170166,506.419769 494.158356,508.466156 489.999268,508.346497 
	C488.834351,508.312988 487.659973,508.414551 486.503723,508.306580 
	C481.907654,507.877411 480.287018,510.118561 480.309387,514.507751 
	C480.365326,525.497986 480.198486,536.489380 480.126709,547.975586 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M575.989868,376.903870 
	C581.938477,380.008484 582.016785,381.108795 576.831848,384.628479 
	C569.663879,389.494354 562.431152,394.267303 555.333435,399.233398 
	C551.865906,401.659546 550.261230,400.635498 549.587891,396.808624 
	C549.183960,394.512756 548.589417,392.249359 548.215515,389.949463 
	C547.567627,385.965057 545.033813,385.651062 542.549988,387.689453 
	C534.443481,394.342194 526.487854,401.190308 518.700378,408.214050 
	C515.868225,410.768341 516.569946,414.523865 517.668701,417.647675 
	C519.765808,423.609650 522.299255,429.420685 524.730164,435.261658 
	C526.009521,438.335632 529.044739,439.238770 531.707642,440.578033 
	C538.106201,443.796051 541.566345,449.030853 541.573669,456.155914 
	C541.580750,463.062164 538.153137,468.226593 532.186523,471.584229 
	C524.867981,475.702698 516.083008,473.473999 510.524414,466.252533 
	C505.848938,460.178406 506.296783,450.044189 511.576569,444.581451 
	C512.838501,443.275787 514.243042,441.965881 515.834534,441.151215 
	C518.845337,439.610107 519.463745,437.626678 518.038757,434.633423 
	C516.393555,431.177551 514.916016,427.640076 513.428894,424.111359 
	C512.191406,421.175079 510.523987,420.973846 508.023621,422.803009 
	C489.662720,436.235443 468.435364,440.276917 446.359497,441.376099 
	C435.202728,441.931580 424.055023,440.627472 412.933960,440.680695 
	C395.453827,440.764343 381.406464,448.285095 372.050903,463.412415 
	C361.075470,481.158844 360.185394,500.802185 361.686127,520.849365 
	C361.873474,523.351868 363.684448,526.905151 360.499420,527.978027 
	C357.373993,529.030884 355.069366,526.045410 353.538300,523.390320 
	C351.261780,519.442383 349.810150,515.084717 349.848816,510.554077 
	C350.088440,482.493469 359.726440,458.459808 381.254761,439.917999 
	C391.260284,431.300476 403.316956,429.610779 416.049988,431.313416 
	C435.174377,433.870728 454.276855,434.138031 473.140015,429.604034 
	C482.884247,427.261871 492.140961,423.449310 500.309143,417.396851 
	C502.666656,415.649963 504.479279,413.870636 504.134369,410.426880 
	C503.826385,407.352142 506.018250,405.158569 508.882202,404.934723 
	C515.050537,404.452484 519.247986,400.697357 523.421204,396.932037 
	C529.108398,391.800659 534.551941,386.399353 540.109192,381.123566 
	C543.120850,378.264404 543.480896,375.163361 541.033997,371.770874 
	C540.741943,371.365936 540.486572,370.934357 540.217346,370.513153 
	C533.854431,360.558472 533.925110,360.660858 543.734619,353.898285 
	C547.529785,351.281952 550.508606,351.472534 553.035645,355.289276 
	C557.600586,362.183838 563.658447,367.632996 569.812317,373.012970 
	C571.584961,374.562622 573.395813,375.997070 575.989868,376.903870 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M506.129944,568.064209 
	C506.535034,544.313354 508.042603,521.054626 507.574951,497.755890 
	C507.498230,493.933746 507.216736,490.114929 507.175598,486.292999 
	C507.141541,483.127167 508.681580,481.254150 512.025574,481.305145 
	C519.662292,481.421631 527.298157,481.615051 534.935181,481.690948 
	C539.268066,481.734009 541.028259,483.810059 541.372314,488.125366 
	C542.114685,497.436523 541.916687,506.746582 541.935608,516.058899 
	C541.983948,539.852600 541.923767,563.646667 542.042114,587.439941 
	C542.064941,592.032593 540.761169,593.906189 535.661499,593.638672 
	C527.885010,593.230591 520.054260,593.778442 512.249512,594.017029 
	C508.334045,594.136780 506.532990,592.717834 506.612488,588.479736 
	C506.737122,581.836426 506.317688,575.182861 506.129944,568.064209 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M580.213867,647.000000 
	C580.195862,654.664551 580.165161,661.829163 580.156860,668.993713 
	C580.153687,671.760864 579.539856,674.127991 576.161316,674.127625 
	C563.002441,674.126282 549.843323,674.074280 536.685059,673.954163 
	C535.427856,673.942688 534.176086,673.326111 532.414795,672.854858 
	C534.195251,670.405090 536.427246,670.804382 538.384644,670.791626 
	C549.381165,670.719604 560.380737,670.591858 571.373840,670.788574 
	C575.585449,670.863953 576.824951,669.268188 576.784668,665.209717 
	C576.617676,648.382690 576.617737,631.552185 576.778564,614.725098 
	C576.817139,610.692566 575.439758,609.816101 571.500061,609.828796 
	C515.683289,610.008667 459.863678,610.275146 404.050018,609.843140 
	C387.737183,609.716919 371.442871,609.716370 355.131287,609.832703 
	C325.317261,610.045349 295.497650,609.502258 265.680695,609.237305 
	C263.631927,609.219116 261.389252,609.641907 259.266541,607.665710 
	C262.395691,605.436646 265.590942,605.894714 268.628662,605.893982 
	C331.277802,605.878784 393.926971,605.900452 456.576111,605.908203 
	C495.065338,605.912964 533.554565,605.897888 572.043823,605.912720 
	C580.090393,605.915833 580.117126,605.958252 580.149963,614.008057 
	C580.194214,624.838623 580.192200,635.669373 580.213867,647.000000 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M398.313354,632.339783 
	C402.171173,632.470520 405.930054,631.333557 408.913544,631.818176 
	C415.418579,632.874878 421.683960,631.738647 428.033539,631.910156 
	C431.727112,632.009888 434.097534,634.931580 434.404602,638.555176 
	C434.852173,643.836487 434.803955,649.167664 434.789490,654.477051 
	C434.782135,657.150757 434.058563,659.422424 430.559662,659.362183 
	C427.097656,659.302673 425.907867,657.459900 425.891266,654.291321 
	C425.871277,650.470215 425.792084,646.646851 425.614105,642.830261 
	C425.543152,641.308228 425.079102,639.702393 423.267090,639.448303 
	C421.181366,639.155945 420.300659,640.747375 419.844086,642.399292 
	C418.762848,646.311096 419.353577,650.336670 419.346069,654.306824 
	C419.339661,657.685669 418.255890,659.949280 414.587769,659.793152 
	C410.831604,659.633301 410.134979,657.118347 410.413788,653.843262 
	C410.737579,650.039917 410.939941,646.201538 410.187012,642.417908 
	C409.930969,641.131348 409.504669,639.689209 407.859314,639.548828 
	C405.990906,639.389343 405.251587,640.774231 404.820526,642.273560 
	C403.743103,646.021301 404.336609,649.875916 404.203094,653.683533 
	C404.105255,656.475525 404.010895,659.161926 400.151733,659.223083 
	C396.625671,659.278992 395.730713,657.301086 395.569153,654.151672 
	C395.263367,648.190979 394.203674,642.255188 395.482666,636.282593 
	C395.829193,634.664246 395.760864,632.869751 398.313354,632.339783 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M220.269714,410.813873 
	C220.043777,413.513885 220.006821,415.837067 219.868134,418.154205 
	C219.706284,420.858154 218.511856,422.388367 215.499771,422.385040 
	C212.305817,422.381470 211.612625,420.677429 211.630966,417.949768 
	C211.689072,409.310791 211.713272,400.670410 211.614655,392.032135 
	C211.578629,388.876526 212.797943,387.596802 216.015518,387.563263 
	C220.965637,387.511749 225.854965,386.926666 230.874878,387.507599 
	C235.319794,388.021942 238.594604,389.372986 240.088867,393.695160 
	C241.738281,398.466125 240.245926,402.647217 235.970825,405.875122 
	C235.326294,406.361816 234.706757,406.881561 233.841339,407.573334 
	C235.920044,410.376801 238.096252,412.884796 239.754547,415.698029 
	C240.864563,417.581116 243.956375,420.032745 241.033722,422.002502 
	C238.366302,423.800201 234.866898,423.097595 232.649857,420.130096 
	C230.564728,417.339142 228.772980,414.325439 226.635788,411.578064 
	C225.016449,409.496399 223.013229,407.924866 220.269714,410.813873 
M219.993713,397.554169 
	C219.889740,399.787354 220.454498,401.590729 223.121109,401.561157 
	C226.148270,401.527649 229.528885,402.514740 231.641418,399.209503 
	C232.445709,397.951111 232.212662,396.682220 231.222061,395.584717 
	C229.054611,393.183319 222.208252,393.896118 219.993713,397.554169 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M519.918640,659.242920 
	C516.056091,658.960144 512.755005,659.179565 509.279938,659.886047 
	C501.558899,661.455750 494.879333,652.776978 496.148956,643.415039 
	C497.395660,634.222656 502.591309,630.705322 513.135559,631.952759 
	C514.495789,630.387085 513.676453,628.464417 513.915649,626.717896 
	C514.339722,623.621521 515.894043,621.824463 519.155701,621.970093 
	C522.429321,622.116150 523.155701,624.214844 523.121765,627.090820 
	C523.015991,636.055237 523.040283,645.021057 522.984497,653.986206 
	C522.971130,656.133179 523.193970,658.458069 519.918640,659.242920 
M513.578003,642.311340 
	C512.275818,639.294983 510.182770,638.131836 507.423737,640.348083 
	C504.522827,642.678345 504.885040,645.870544 506.158936,648.912720 
	C506.978149,650.869019 508.590698,652.267517 510.838440,651.585632 
	C514.076538,650.603394 513.885925,647.706299 513.917664,645.061707 
	C513.925537,644.408447 513.803284,643.753662 513.578003,642.311340 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M372.368896,415.270447 
	C373.579193,413.830444 373.998871,412.418732 374.230255,410.468994 
	C368.770691,412.139465 364.200439,411.207306 360.972870,406.221832 
	C358.060242,401.722778 357.897339,397.202667 360.525940,392.582825 
	C364.723480,385.205414 374.786041,384.761078 379.665253,391.714081 
	C384.228882,398.217377 384.462738,411.240845 380.136383,417.950531 
	C375.853271,424.593170 365.622314,425.262634 361.477905,419.116913 
	C360.391968,417.506500 358.892517,415.572540 361.031189,413.827667 
	C363.000916,412.220703 365.367737,412.027649 367.325562,413.994965 
	C368.728851,415.405060 369.744965,417.475952 372.368896,415.270447 
M367.219879,401.001862 
	C367.597656,403.178345 368.453156,405.015442 371.081879,404.439728 
	C373.166412,403.983185 373.867371,402.204987 373.892822,400.225311 
	C373.926025,397.640381 373.766693,394.667938 370.548798,394.452423 
	C366.423309,394.176117 367.248657,397.651398 367.219879,401.001862 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M332.597412,399.893860 
	C332.771240,392.016052 336.207642,387.711548 342.684113,386.815155 
	C347.973175,386.083160 353.801056,390.395660 355.414368,396.605896 
	C357.177246,403.391998 356.972717,410.205627 354.056000,416.657623 
	C350.486816,424.552826 339.652924,425.845551 334.688080,419.074646 
	C333.547302,417.518860 331.878021,415.680634 334.036560,413.842743 
	C335.839142,412.307892 338.042206,412.357544 340.062469,413.711853 
	C345.207123,417.160645 346.024719,416.844177 347.520203,410.350616 
	C343.512390,411.230499 339.613647,411.785461 336.330444,408.709656 
	C333.885986,406.419708 332.459869,403.743256 332.597412,399.893860 
M340.251495,400.945770 
	C340.670197,403.121185 341.687897,404.915955 344.173645,404.487640 
	C346.666412,404.058105 347.647308,402.029785 347.584808,399.655273 
	C347.515869,397.037933 346.990906,394.388184 343.648865,394.363800 
	C339.534973,394.333771 340.265564,397.581848 340.251495,400.945770 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M280.640350,631.437988 
	C289.582642,630.499939 298.915985,637.684326 294.339386,648.574768 
	C293.413239,650.778625 296.083832,652.900452 293.640472,655.426453 
	C289.462769,659.745361 284.312256,661.395142 278.250763,659.071472 
	C273.160950,657.120239 269.899261,651.639282 270.242584,645.379883 
	C270.611786,638.649353 273.269257,633.505310 280.640350,631.437988 
M281.029480,650.884644 
	C283.259369,653.554199 284.804321,652.720276 286.647858,649.951477 
	C284.206635,649.029602 282.475433,648.498108 281.029480,650.884644 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M264.561371,414.703369 
	C265.513367,413.722260 266.306335,413.043152 266.760223,412.184784 
	C268.538025,408.822723 271.444427,408.854279 273.931213,410.517731 
	C276.850769,412.470673 274.859344,415.038940 273.481567,417.015564 
	C267.754242,425.232056 255.278976,425.514191 248.868912,417.664307 
	C242.958511,410.426331 243.227524,398.318359 249.453400,391.359955 
	C255.505814,384.595276 268.029968,384.779053 273.374542,391.770844 
	C274.664337,393.458130 276.441101,395.567230 274.360626,397.483643 
	C272.438263,399.254456 269.832489,399.355316 267.469116,397.652283 
	C261.591644,393.417114 258.255737,393.091522 255.495102,396.440277 
	C252.105286,400.552277 252.545776,410.936432 256.347015,414.134003 
	C258.735321,416.143066 261.440186,416.391785 264.561371,414.703369 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M317.685181,658.683167 
	C311.276398,654.742371 309.971436,648.922668 310.897003,642.492126 
	C311.658203,637.203735 314.642548,633.486328 320.027100,631.814331 
	C328.585266,629.156921 337.378571,636.594177 336.057617,645.461365 
	C335.745636,647.555786 334.281372,648.473267 332.187439,649.154419 
	C336.662720,652.038513 335.277954,654.656555 332.521149,656.988159 
	C328.147949,660.686768 323.197998,660.849060 317.685181,658.683167 
M322.963989,652.286011 
	C325.053650,652.530579 326.270355,651.674377 326.807861,649.355774 
	C324.562775,649.125549 322.679199,648.808960 320.989746,649.821960 
	C321.023804,651.128540 321.670990,651.615845 322.963989,652.286011 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M398.093964,387.658813 
	C400.404816,387.656555 402.236298,387.680176 404.066742,387.646454 
	C406.756744,387.596832 409.247223,387.796143 409.108551,391.449280 
	C408.974487,394.980316 406.444458,395.068665 403.810577,395.222595 
	C401.475342,395.359100 398.853394,394.245789 396.780090,396.415070 
	C397.694092,398.839905 399.780304,398.302612 401.452087,398.638458 
	C406.564117,399.665527 410.070312,403.836395 410.326141,409.217682 
	C410.655823,416.153290 407.492340,421.273804 401.859802,422.921661 
	C396.340790,424.536255 390.353210,422.561615 387.730927,418.160889 
	C386.718689,416.462128 385.411713,414.441162 387.565216,412.839752 
	C389.407043,411.470154 391.630280,411.458221 393.583984,413.019348 
	C393.969360,413.327301 394.287201,413.724823 394.613770,414.100555 
	C396.103607,415.814697 397.779419,417.545563 400.062042,415.631927 
	C402.781311,413.352264 402.371002,410.169800 401.237579,407.353485 
	C400.238342,404.870697 397.938751,404.968750 395.679901,405.796661 
	C394.283447,406.308441 392.867218,407.062256 391.435883,407.120789 
	C388.482635,407.241577 386.571259,406.008972 387.265381,402.585999 
	C387.793823,399.980072 388.338623,397.377380 388.855225,394.769104 
	C390.247284,387.741272 390.290100,387.701996 398.093964,387.658813 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M386.980042,633.817017 
	C393.321594,639.269592 394.721558,645.974854 391.214417,652.780884 
	C388.110596,658.804321 382.326202,661.203308 375.183563,659.429504 
	C367.628326,657.553223 363.113342,649.288757 365.381256,641.486572 
	C367.659210,633.649658 375.339142,629.511902 383.013855,632.032349 
	C384.267670,632.444153 385.447937,633.079834 386.980042,633.817017 
M381.204529,640.384033 
	C378.807159,638.628601 376.800354,639.362732 375.300537,641.565857 
	C373.467316,644.258606 373.424469,647.238159 375.196228,649.911438 
	C376.359497,651.666504 378.378387,652.372803 380.449249,651.391602 
	C383.228363,650.074768 383.775970,647.527100 383.677826,644.735352 
	C383.622192,643.152222 382.869141,641.918030 381.204529,640.384033 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M457.437134,408.390381 
	C455.832886,410.415955 453.409576,411.354736 452.932648,413.954590 
	C454.982117,415.313110 457.213898,414.471802 459.279968,414.795746 
	C461.733917,415.180450 463.221344,416.346405 463.221222,419.028931 
	C463.221130,421.789398 461.645660,422.959076 459.156281,423.001648 
	C453.840454,423.092560 448.515320,423.228058 443.207428,423.013428 
	C438.793335,422.834961 437.638031,420.535278 439.971375,416.714264 
	C442.764832,412.139832 446.964569,408.732819 450.443237,404.722473 
	C451.847748,403.103241 453.341583,401.462433 454.291229,399.577728 
	C455.111053,397.950623 455.227325,395.788086 453.197449,394.756012 
	C451.339355,393.811279 449.094513,393.657349 447.942596,395.835419 
	C446.654510,398.270935 445.068970,399.350739 442.349335,398.516449 
	C440.982574,398.097229 439.890411,397.467438 439.765625,395.883545 
	C439.547211,393.110931 440.880188,391.104370 443.015625,389.547363 
	C448.460663,385.577271 456.349548,386.001373 460.380280,390.457123 
	C464.743225,395.280121 463.810333,401.415924 457.437134,408.390381 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M324.249512,422.597412 
	C319.659241,421.851562 315.575073,423.164825 311.428802,423.106110 
	C309.282013,423.075714 306.801758,423.362885 305.575989,421.220154 
	C304.418579,419.196869 306.126953,417.412384 307.118683,415.767029 
	C309.100464,412.479065 311.546783,409.538910 314.593506,407.158417 
	C316.980438,405.293365 318.956848,403.052734 320.503601,400.480530 
	C321.690033,398.507538 322.297760,396.230194 319.827545,394.826385 
	C317.785095,393.665680 315.617920,393.794464 314.322784,396.266663 
	C313.767059,397.327423 313.117645,398.289032 311.932465,398.666840 
	C310.231842,399.208954 308.599396,398.904266 307.233612,397.800781 
	C305.599121,396.480316 306.155182,394.839294 306.855316,393.268372 
	C309.286133,387.814301 317.206665,385.226776 323.182068,387.922089 
	C328.935425,390.517273 330.789246,396.450073 327.999329,402.618225 
	C325.978668,407.085724 321.539001,409.625244 319.107117,413.847595 
	C320.880310,415.351288 322.784363,414.743835 324.509094,414.789093 
	C327.426544,414.865692 329.326904,415.719940 329.250000,419.082977 
	C329.177460,422.254883 327.453217,423.087738 324.249512,422.597412 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M246.464340,629.744263 
	C244.004868,628.175293 243.349091,626.196350 244.236374,623.941162 
	C245.049011,621.875671 247.123337,621.889954 248.935471,621.877991 
	C255.415466,621.835266 261.896362,621.927856 268.376343,621.884216 
	C271.559662,621.862732 273.423676,622.946838 273.365845,626.490906 
	C273.310150,629.904114 271.450195,630.821472 268.494110,630.442444 
	C264.096893,629.878540 263.072144,632.207764 263.186432,636.067383 
	C263.368347,642.210571 263.221863,648.362854 263.276001,654.510742 
	C263.301239,657.380249 262.384247,659.431580 259.197510,659.656494 
	C255.868393,659.891479 254.135925,658.017334 254.031433,654.939575 
	C253.845505,649.463440 253.992004,643.976746 253.960983,638.494629 
	C253.920639,631.368713 253.904480,631.368774 246.464340,629.744263 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M414.794312,419.094360 
	C413.403290,416.808167 411.523407,414.645111 414.264679,412.731323 
	C416.585144,411.111389 418.900299,411.565002 420.806000,413.962372 
	C422.010254,415.477295 423.400726,417.272491 425.794220,416.062347 
	C427.377563,415.261810 428.447510,413.774750 428.363129,412.066620 
	C428.253723,409.851532 427.403137,407.761505 424.774078,407.360870 
	C422.961517,407.084656 421.498016,406.415558 421.199463,404.489227 
	C420.910828,402.626984 422.001740,401.431702 423.539032,400.469482 
	C425.501587,399.241089 428.171387,397.755646 425.849609,395.157043 
	C423.925537,393.003540 422.035004,394.774078 420.561646,396.614563 
	C418.687531,398.955597 416.360504,398.547363 414.420074,396.926697 
	C412.382294,395.224670 413.671448,393.255768 414.807343,391.609039 
	C417.283173,388.019836 420.839325,386.388702 425.115845,386.826935 
	C429.310852,387.256805 432.731201,389.118988 434.373657,393.326813 
	C434.673462,394.094940 435.323334,395.196228 435.034821,395.652679 
	C431.536713,401.186890 435.061157,405.980530 436.211731,411.152191 
	C437.377960,416.393860 434.207336,420.187927 429.643616,422.245728 
	C424.309143,424.651062 419.270721,423.604736 414.794312,419.094360 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M452.236694,635.474060 
	C452.360657,632.198242 452.631653,629.365112 452.463257,626.558289 
	C452.267273,623.290833 453.748962,621.954224 456.808105,621.922974 
	C460.007690,621.890259 462.093933,623.253601 461.679932,626.672424 
	C460.873444,633.332458 462.333710,639.965942 461.716248,646.595337 
	C461.433319,649.632935 462.536438,650.940125 465.565643,650.855164 
	C468.389374,650.775818 471.225159,650.724060 474.041565,650.893616 
	C477.011780,651.072388 479.196442,652.382935 479.148529,655.777832 
	C479.097351,659.401184 476.466522,659.423279 473.861572,659.409973 
	C468.374146,659.381897 462.883453,659.226257 457.400482,659.368896 
	C453.780365,659.463074 452.189209,657.885071 452.196320,654.380981 
	C452.208801,648.230103 452.193268,642.079224 452.236694,635.474060 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M350.302551,639.431458 
	C346.375732,641.742371 346.751434,645.173035 347.574249,648.418518 
	C348.457520,651.902649 351.098846,652.573792 353.969971,650.395569 
	C354.757294,649.798157 355.433411,648.959045 356.311523,648.595215 
	C358.457275,647.706238 360.774902,647.880188 362.303497,649.581970 
	C363.890564,651.348816 362.806396,653.361938 361.610016,655.040405 
	C357.100159,661.367554 347.152863,661.750122 341.412018,655.845154 
	C336.942841,651.248169 336.585876,641.780029 340.678711,636.393433 
	C344.728424,631.063599 352.566345,629.634521 358.613953,633.418945 
	C361.041351,634.937988 364.442657,636.922729 362.149933,640.410095 
	C360.210846,643.359680 356.982666,642.892456 354.246826,640.761658 
	C353.177979,639.929199 352.136322,639.119751 350.302551,639.431458 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M492.517761,639.329102 
	C489.944336,643.218018 490.543518,649.971252 493.594208,652.302795 
	C495.468994,653.735657 496.156342,655.310425 495.439026,657.434082 
	C494.754883,659.459717 492.961945,659.962097 491.062622,660.128174 
	C485.637115,660.602783 482.258026,657.566040 481.827759,652.163330 
	C481.420105,647.044678 483.054321,641.773987 479.263367,636.907776 
	C477.316406,634.408569 484.110718,624.757385 487.575958,624.143372 
	C489.973969,623.718567 490.498199,625.363892 490.714600,627.035461 
	C491.021118,629.403381 491.586700,631.160461 493.818970,632.787964 
	C496.564209,634.789429 495.669189,637.393555 492.517761,639.329102 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M211.688660,626.007935 
	C211.766006,620.190308 211.522278,614.870422 212.473099,609.572021 
	C212.806427,607.714661 213.143814,606.174622 215.215012,606.108704 
	C223.698242,605.838501 232.189667,605.404175 240.658539,606.356873 
	C241.190445,606.416626 241.656296,607.063599 242.141357,607.428711 
	C241.038803,609.927429 238.968414,609.252502 237.264038,609.269775 
	C231.265320,609.330444 225.263138,609.360168 219.266708,609.216614 
	C215.810272,609.133911 214.343231,610.782776 214.415405,614.019592 
	C214.537918,619.513245 214.559082,625.018921 214.962128,630.494507 
	C215.807724,641.982788 213.588120,653.443787 214.936264,664.947937 
	C215.648956,671.029602 216.190475,671.637695 222.162674,671.569946 
	C228.479385,671.498169 234.781113,671.451172 241.084366,670.919861 
	C243.301895,670.732849 245.787537,670.427734 247.562561,672.554932 
	C246.380936,674.709900 244.327393,674.142883 242.628082,674.083252 
	C234.659607,673.803589 226.719452,673.547302 218.730118,674.144104 
	C212.335052,674.621826 211.714462,673.590271 211.717331,666.995239 
	C211.723190,653.498840 211.700150,640.002441 211.688660,626.007935 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M359.855347,670.971375 
	C376.048218,671.905640 391.799103,669.777283 407.516907,671.365295 
	C407.838440,671.397888 408.096039,672.063232 408.392944,672.445557 
	C403.693390,675.065857 295.170197,674.965393 288.820404,671.712952 
	C313.162720,669.175049 336.330383,672.735840 359.855347,670.971375 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M281.976746,401.928711 
	C279.893890,398.786804 280.498810,396.403015 283.435272,394.611420 
	C286.010925,393.040009 288.234222,391.071533 290.153564,388.739838 
	C291.398041,387.227997 292.978241,386.385803 294.982819,386.950439 
	C297.268951,387.594421 297.283051,389.524872 297.286530,391.377869 
	C297.303253,400.345062 297.299744,409.312347 297.294556,418.279572 
	C297.293365,420.332703 297.312531,422.540680 294.448639,422.567749 
	C291.646790,422.594269 290.086151,420.760925 289.896393,418.214081 
	C289.600952,414.249695 289.719055,410.253998 289.678375,406.271393 
	C289.658264,404.304260 289.674896,402.336731 289.674896,400.391296 
	C286.571716,399.469574 284.943939,402.721191 281.976746,401.928711 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M307.377075,649.946228 
	C307.358551,651.931702 307.319031,653.425720 307.326202,654.919434 
	C307.337585,657.293091 306.946533,659.189392 303.876984,659.228577 
	C301.042389,659.264709 299.374420,658.069153 299.201355,655.273132 
	C298.945404,651.138245 298.801239,646.990662 298.789581,642.847900 
	C298.774139,637.371155 298.911621,631.893494 299.024902,626.417114 
	C299.087402,623.396179 300.539856,621.798157 303.711517,622.017151 
	C306.935242,622.239746 307.717834,624.001831 307.611115,627.055542 
	C307.350433,634.515503 307.432739,641.987488 307.377075,649.946228 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M486.642548,674.123657 
	C466.210480,673.118652 446.198761,673.393372 425.179413,673.256226 
	C429.629974,669.821899 487.250244,669.940002 491.447327,672.598328 
	C490.516632,674.595459 488.663361,673.953796 486.642548,674.123657 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M528.889526,652.118530 
	C531.750732,650.203186 534.550537,649.767639 536.697876,652.334534 
	C537.992249,653.881775 538.020569,656.232117 536.877625,658.010498 
	C535.427979,660.265991 532.891052,659.679260 530.931213,659.135742 
	C527.227783,658.108643 528.775024,654.908630 528.889526,652.118530 
z"/>
<path fill="#000000" opacity="1.000000" stroke="none" 
	d="
M529.939331,640.112061 
	C528.862183,637.056030 526.503540,633.631226 531.707397,632.706177 
	C534.498047,632.210083 537.052124,632.558167 537.227783,636.474670 
	C537.398132,640.269775 535.217896,640.970032 532.189453,640.812988 
	C531.543640,640.779541 530.910400,640.504272 529.939331,640.112061 
z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
